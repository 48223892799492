import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import Layout from "../../components/Layout";
import styled from "styled-components";

import BgImage1 from "../../img/news-bg.png";

const StayPage = ({ pageContext }) => {
  const { breadcrumb: { crumbs }} = pageContext;

  return(
    <Layout>
      <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="宿泊予約"  />
      <MidashiBg>
        <Midashi>宿泊予約</Midashi>
      </MidashiBg>
      <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScMpGCi2T7l9_BIRHmEKnUnZ1_JIXsxqjzbPpP-KxJPubxDgA/formResponse">
        <div>
          <label for="email">メールアドレス</label><br />
          <InputText id="email" type="email" required="required" name="emailAddress" />
        </div>
        <br />

        <div>
        <label for="name">代表者氏名</label><br />
        <InputText id="name" type="text" required="required" name="entry.1605508120"/>
        </div>
        <br />

        <div>
        <label for="tel">電話番号</label><br />
        <InputText id="tel" type="text" required="required" name="entry.1235682697"/>
        </div>
        <br />

        <div>
        <label for="address">住所</label><br />
        <InputText id="address" type="text" required="required" name="entry.1648586441"/>
        </div>
        <br />

        <div>
        <label for="date">チェックイン日　</label>
        <input id="date" type="date" required="required" name="entry.1258366302"/>
        </div>
        <br />

        <div>
        <label for="time">チェックイン時間　</label>
        <input id="time" list="timelist" name="entry.1288013820"/>
        <datalist id="timelist">
          <option value="16:00" />
          <option value="17:00" />
          <option value="18:00" />
          <option value="19:00" />
          <option value="20:00" />
          <option value="21:00" />
        </datalist>
        </div>
        <br />

        <div>
        <label for="howlong">宿泊日数</label><br />
        <InputText id="howlong" type="text" name="entry.272213326"/>
        </div>
        <br />

        <div>
        <label for="howmany">宿泊者人数　(男性○名/女性○名）</label><br />
        <InputText id="howmany" type="text" name="entry.849185241"/>
        </div>
        <br />

        <div>
        <label>宿泊のお部屋タイプをお選びください（複数選択可）</label><br />
        <label for="type1">個室【1〜2名】 ( 1名1泊 ¥5000 )　</label>
        <input id="type1" type="checkbox" name="entry.1742252726" value="個室【1〜2名】 ( 1名1泊 ¥4000 )"/><br />
        <label for="type2">グループでの貸し切り個室【3〜6名】 ( 1名1泊 ¥4500 )　</label>
        <input id="type2" type="checkbox" name="entry.1742252726" value="グループでの貸し切り個室【3〜6名】 ( 1名1泊 ¥4000 )"/><br />
        <label for="type3">女性専用ドミトリー【相部屋】(1名1泊¥3500 )　</label>
        <input id="type3" type="checkbox" name="entry.1742252726" value="女性専用ドミトリー【相部屋】(1名1泊¥3000 )"/>
        </div>
        <br />

        <div>
        <p>日中滞在をご希望の方はお電話予約をお願いします 086-250-2629</p>
        </div>
        <br />

        <div>
        <label for="transport">当館までの交通手段　</label>
        <input id="transport" list="transportlist" required="required" name="entry.408339395"/>
        <datalist id="transportlist">
          <option value="公共交通機関、徒歩" />
          <option value="車(コインパーキングをご利用いただきます）" />
        </datalist>
        </div>
        <br />
        
        <div>
        <label for="staytimes">当館への宿泊経験　</label>
        <input type="radio" name="entry.857321086" value="初めて" id="staytimes1" /><label for="staytimes1">初めて　</label>
        <input type="radio" name="entry.857321086" value="２回目" id="staytimes2" /><label for="staytimes2">２回目　</label>
        <input type="radio" name="entry.857321086" value="２回以上" id="staytimes3" /><label for="staytimes3">２回以上　</label>
        </div>
        <br />

        <div>
        <label for="message">メッセージ ・ご質問をどうぞ</label>
        <InputText id="message" type="text" name="entry.1870586564"/>
        </div>
        <br />
        <Button type="submit" value="送信">送信</Button>
      </form>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="宿泊予約"  />
      </Body>
    </Layout>
  );
}

export default StayPage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  text-align: left;
`;

const MidashiBg = styled.div`
  width: 100%;
  height: 15em;
  background-image: url(${BgImage1});
  padding: 5em 15vw 0 15vw;
  margin: 0 0 5em 0;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  text-align: center;
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 20pt;
`;

const InputText = styled.input`
width: 20em;
border: 0;
border-bottom: 1px solid #1b2538;
background-color: transparent;

  &:focus{
  transition: 0.4s;
  outline: none;
  }
`;

const Button = styled.button`
  background: #666666;
  width: 20vw;
  height: 10vh;
  margin: 5vh 0 10vh 0;
  border: none;
  border-radius: 10px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 14pt;
  color: #ffffff;

  &:hover {
    background: #f6f5f1;
    border: 1px #666666 solid;
    border-radius: 10px;
    color: #666666;
    pointer: cursor;
  }
`;
